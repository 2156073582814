import '../../utils/polyfills'

import React, { FC, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import MobileDetect from 'mobile-detect'

import { GlobalStyles, themes, themesKey } from '../../theme'
import Seo, { SeoProps } from '../Seo'
import DefaultHead from '../DefaultHead'
import { selectors } from '../../redux'
import { useWindowSize } from '../../hooks/useWindowSize'

type AppProps = {
  seo: SeoProps
}

import * as SC from './styled'

const App: FC<AppProps> = (props) => {
  const { children, seo } = props

  // selectors

  const currentTheme = useSelector(selectors.app.theme) as themesKey

  // state

  const [showRedirectToSafari, setShowRedirectToSafari] = useState(false)

  // handlers

  const handleResize = useCallback(() => {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }, [])

  const handleOrientationChange = useCallback(() => {
    handleResize()
    setTimeout(() => {
      handleResize()
    }, 500)
  }, [handleResize])

  // hook

  useWindowSize(handleResize)

  // effect

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent)

    // check compatibility
    if (!navigator.mediaDevices?.enumerateDevices) {
      if (md.os() === 'iOS') {
        setShowRedirectToSafari(true)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange)
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [handleOrientationChange])

  // return

  return (
    <>
      <GlobalStyles />
      <Helmet />
      <ThemeProvider theme={themes[currentTheme]}>
        <DefaultHead />
        <Seo {...seo} />
        <Helmet />
        {children}
        {showRedirectToSafari && (
          <SC.Lightbox>
            <SC.RedirectToSafariPopup>
              For a better experience, please play the experience on Safari
            </SC.RedirectToSafariPopup>
          </SC.Lightbox>
        )}
      </ThemeProvider>
    </>
  )
}

export default App
