import { Helmet } from 'react-helmet'
import React, { FC } from 'react'

const DefaultHead: FC = () => {
  return (
    <Helmet>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {/* Android */}
      <meta name="mobile-web-app-capable" content="yes" />
      {/* Apple */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
    </Helmet>
  )
}

export default DefaultHead
