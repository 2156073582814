import React, { FC } from 'react'
import cx from 'classnames'

import App from '../App'
import { SeoProps } from '../Seo'

type LayoutProps = {
  className?: string
  header?: React.ReactNode
  footer?: React.ReactNode
  seo?: SeoProps
}

const Layout: FC<LayoutProps> = (props) => {
  const { children, className, header = null, footer = null, seo = {} } = props

  return (
    <App seo={seo}>
      <div className={cx(className)}>
        <>{header}</>
        <main>{children}</main>
        <>{footer}</>
      </div>
    </App>
  )
}

export default Layout
