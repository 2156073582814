import styled from 'styled-components'

import { PhotoCanvas, Webcam as WebcamComponent } from '../../components'
import { colors } from '../../theme'

export const DebugTemplate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.pureWhite};
  width: 100vw;
  height: 100vh;
`

export const Webcam = styled(WebcamComponent)`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 120px;
`

export const Canvas = styled(PhotoCanvas)`
  //width: 70%;
  max-width: 70%;
  canvas {
    border: 1px solid red;
  }
`

export const Buttons = styled.div`
  position: absolute;
  top: 10px;
  left: 200px;
  button {
    padding: 5px 10px;
    margin: 0 10px;
  }
`
