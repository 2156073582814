import { Mode, Theme } from '../../types/global'

const PIXI = typeof window !== `undefined` ? require('pixi.js') : null

// for devtools
if (typeof window !== `undefined`) {
  ;(window as any).PIXI = PIXI
}

const assets = {
  soloMask: { name: 'soloMask', url: '/static/images/soloMask.png' },
  soloHeartBalenciaga: {
    name: 'soloHeartBalenciaga',
    url: '/static/images/soloHeartBalenciaga.png',
  },
  soloHeartBling: { name: 'soloHeartBling', url: '/static/images/soloHeartBling.png' },
  soloHeartKitty: { name: 'soloHeartKitty', url: '/static/images/soloHeartKitty.png' },
  duoMask1: { name: 'duoMask1', url: '/static/images/duoMask1.png' },
  duoMask2: { name: 'duoMask2', url: '/static/images/duoMask2.png' },
  duoHeartBalenciaga1: {
    name: 'duoHeartBalenciaga1',
    url: '/static/images/duoHeartBalenciaga1.png',
  },
  duoHeartBalenciaga2: {
    name: 'duoHeartBalenciaga2',
    url: '/static/images/duoHeartBalenciaga2.png',
  },
  duoHeartBling1: { name: 'duoHeartBling1', url: '/static/images/duoHeartBling1.png' },
  duoHeartBling2: { name: 'duoHeartBling2', url: '/static/images/duoHeartBling2.png' },
  duoHeartKitty1: { name: 'duoHeartKitty1', url: '/static/images/duoHeartKitty1.png' },
  duoHeartKitty2: { name: 'duoHeartKitty2', url: '/static/images/duoHeartKitty2.png' },
  emptyPattern: { name: 'emptyPattern', url: '/static/images/emptyPattern.png' },
  whitePattern: { name: 'whitePattern', url: '/static/images/whitePattern.png' },
}

export class PixiCanvas {
  app: PIXI.Application
  loader = PIXI.Loader.shared
  mode = Mode.solo
  theme = Theme.bling
  modeContainers = {
    [Mode.solo]: new PIXI.Container(),
    [Mode.duo]: new PIXI.Container(),
  }
  duoContainers = [new PIXI.Container(), new PIXI.Container()]
  heartTextures = {
    [Mode.solo]: {
      [Theme.balenciaga]: assets.soloHeartBalenciaga.name,
      [Theme.bling]: assets.soloHeartBling.name,
      [Theme.kitty]: assets.soloHeartKitty.name,
    },
    [Mode.duo]: {
      [Theme.balenciaga]: [assets.duoHeartBalenciaga1.name, assets.duoHeartBalenciaga2.name],
      [Theme.bling]: [assets.duoHeartBling1.name, assets.duoHeartBling2.name],
      [Theme.kitty]: [assets.duoHeartKitty1.name, assets.duoHeartKitty2.name],
    },
  }
  emptySprites = {
    [Mode.solo]: new PIXI.TilingSprite(PIXI.Texture.EMPTY),
    [Mode.duo]: [
      new PIXI.TilingSprite(PIXI.Texture.EMPTY),
      new PIXI.TilingSprite(PIXI.Texture.EMPTY),
    ],
  }
  webcamSprites = {
    [Mode.solo]: new PIXI.Sprite(),
    [Mode.duo]: [new PIXI.Sprite(), new PIXI.Sprite()],
  }
  webcamMask = {
    [Mode.solo]: new PIXI.Sprite(),
    [Mode.duo]: [new PIXI.Sprite(), new PIXI.Sprite()],
  }
  heartSprites = {
    [Mode.solo]: new PIXI.Sprite(),
    [Mode.duo]: [new PIXI.Sprite(), new PIXI.Sprite()],
  }
  heartSizes: { [key in Mode]: { width: number; height: number } }
  isWebcamReady = false
  isAssetsLoaded = false
  isIntermediatePictureTaken = false

  constructor() {
    this.app = new PIXI.Application({
      width: 800,
      height: 800,
      transparent: true,
      resolution: 1,
    })

    this.heartSizes = {
      [Mode.solo]: {
        width: this.app.view.width * 0.89,
        height: this.app.view.height * 0.89,
      },
      [Mode.duo]: {
        width: this.app.view.width * 0.64,
        height: this.app.view.height * 0.64,
      },
    }

    this.loadAssets()
  }

  loadAssets = () => {
    this.loader.add(Object.values(assets)).load(() => {
      this.isAssetsLoaded = true
      this.setup()
    })
  }

  setup = () => {
    this.updateMode()
    this.updateTheme()

    // SOLO MODE

    this.setupSoloMode()

    // DUO MODE

    this.setUpDuoMode()

    // ADD TO STAGE

    this.app.stage.addChild(this.modeContainers[Mode.solo], this.modeContainers[Mode.duo])
  }

  setupSoloMode() {
    // EMPTY PATTERN

    this.emptySprites[Mode.solo].texture = this.loader.resources[assets.emptyPattern.name].texture
    this.emptySprites[Mode.solo].width = this.heartSizes[Mode.solo].width
    this.emptySprites[Mode.solo].height = this.heartSizes[Mode.solo].height

    // WEBCAM

    this.webcamSprites[Mode.solo].anchor.set(0.5)
    // centered
    this.webcamSprites[Mode.solo].position.set(
      this.heartSizes[Mode.solo].width / 2,
      this.heartSizes[Mode.solo].height / 2
    )

    // MASK

    this.webcamMask[Mode.solo].texture = this.loader.resources[assets.soloMask.name].texture
    this.webcamMask[Mode.solo].anchor.set(0.5)
    // centered
    this.webcamMask[Mode.solo].position.set(
      this.heartSizes[Mode.solo].width / 2,
      this.heartSizes[Mode.solo].height / 2
    )
    // 100% of heart width
    this.webcamMask[Mode.solo].scale.set(
      this.heartSizes[Mode.solo].width / this.webcamMask[Mode.solo].width
    )
    // apply mask
    this.webcamSprites[Mode.solo].mask = this.webcamMask[Mode.solo]
    this.emptySprites[Mode.solo].mask = this.webcamMask[Mode.solo]

    // HEART

    this.heartSprites[Mode.solo].anchor.set(0.5)
    // centered
    this.heartSprites[Mode.solo].position.set(
      this.heartSizes[Mode.solo].width / 2,
      this.heartSizes[Mode.solo].height / 2
    )
    // 100% of heart width
    this.heartSprites[Mode.solo].scale.set(
      this.heartSizes[Mode.solo].width / this.heartSprites[Mode.solo].width
    )

    // CONTAINER

    // add child
    this.modeContainers[Mode.solo].addChild(
      this.emptySprites[Mode.solo],
      this.webcamMask[Mode.solo],
      this.webcamSprites[Mode.solo],
      this.heartSprites[Mode.solo]
    )

    // center in app
    this.modeContainers[Mode.solo].position.set(this.app.view.width / 2, this.app.view.height / 2)
    this.modeContainers[Mode.solo].pivot.set(
      this.modeContainers[Mode.solo].width / 2,
      this.modeContainers[Mode.solo].height / 2
    )
  }

  setUpDuoMode() {
    // EMPTY PATTERN

    this.emptySprites[Mode.duo].forEach((sprite, i) => {
      sprite.texture = this.loader.resources[assets.emptyPattern.name].texture
      sprite.width = this.heartSizes[Mode.duo].width
      sprite.height = this.heartSizes[Mode.duo].height
      // add to containers
      this.duoContainers[i].addChild(sprite)
    })

    // WEBCAM

    this.webcamSprites[Mode.duo].forEach((sprite, i) => {
      sprite.anchor.set(0.5)
      // centered
      sprite.position.set(this.heartSizes[Mode.duo].width / 2, this.heartSizes[Mode.duo].height / 2)
      // add to containers
      this.duoContainers[i].addChild(sprite)
    })

    this.webcamSprites[Mode.duo][1].alpha = 0.15

    // MASK

    this.webcamMask[Mode.duo].forEach((mask, i) => {
      mask.texture = this.loader.resources[
        assets[`duoMask${i + 1}` as keyof typeof assets].name
      ].texture
      mask.anchor.set(0.5)
      // centered
      mask.position.set(this.heartSizes[Mode.duo].width / 2, this.heartSizes[Mode.duo].height / 2)
      // 100% of heart width
      mask.scale.set(this.heartSizes[Mode.duo].width / mask.width)
      // apply mask
      this.webcamSprites[Mode.duo][i].mask = mask
      this.emptySprites[Mode.duo][i].mask = mask
      // add to containers
      this.duoContainers[i].addChild(mask)
    })

    // HEART

    this.heartSprites[Mode.duo].forEach((sprite, i) => {
      sprite.anchor.set(0.5)
      sprite.position.set(this.heartSizes[Mode.duo].width / 2, this.heartSizes[Mode.duo].height / 2)
      // 100% of heart width
      sprite.scale.set(this.heartSizes[Mode.duo].width / sprite.width)
      this.duoContainers[i].addChild(sprite)
    })

    // CONTAINERS

    this.duoContainers.forEach((container) =>
      container.pivot.set(container.width / 2, container.height / 2)
    )

    this.duoContainers[0].position.set(this.app.view.width * 0.39, this.app.view.height * 0.295)
    this.duoContainers[1].position.set(this.app.view.width * 0.61, this.app.view.height * 0.675)

    this.modeContainers[Mode.duo].addChild(this.duoContainers[1], this.duoContainers[0])
  }

  setWebcamVideo = (video: HTMLVideoElement) => {
    const videoTexture = PIXI.Texture.from(video)
    const videoRatio = video.videoWidth / video.videoHeight

    // SOLO

    this.webcamSprites[Mode.solo].texture = videoTexture

    if (!this.isWebcamReady) {
      const soloHeartRatio = this.heartSizes[Mode.solo].width / this.heartSizes[Mode.solo].height
      // scale to "cover"
      const soloScale =
        videoRatio > soloHeartRatio
          ? (this.heartSizes[Mode.solo].height * 0.88) / this.webcamSprites[Mode.solo].height
          : (this.heartSizes[Mode.solo].width * 0.88) / this.webcamSprites[Mode.solo].width
      this.webcamSprites[Mode.solo].scale.set(soloScale)
      // mirrored webcam
      this.webcamSprites[Mode.solo].scale.x *= -1
    }

    // DUO

    this.webcamSprites[Mode.duo].forEach((sprite) => {
      sprite.texture = videoTexture
      if (!this.isWebcamReady) {
        const duoHeartRatio = this.heartSizes[Mode.duo].width / this.heartSizes[Mode.duo].height
        // scale to "cover"
        const duoScale =
          videoRatio > duoHeartRatio
            ? (this.heartSizes[Mode.duo].height * 0.88) / sprite.height
            : (this.heartSizes[Mode.duo].width * 0.88) / sprite.width
        sprite.scale.set(duoScale)
        // mirrored webcam
        sprite.scale.x *= -1
      }
    })

    this.emptySprites[Mode.duo][1].texture = this.loader.resources[assets.whitePattern.name].texture

    this.isWebcamReady = true
  }

  reset = () => {
    this.webcamSprites[Mode.duo][0].texture = PIXI.Texture.EMPTY
    this.webcamSprites[Mode.duo][1].texture = PIXI.Texture.EMPTY
    this.webcamSprites[Mode.duo][1].alpha = 0.15
    this.emptySprites[Mode.duo][1].texture = this.loader.resources[assets.emptyPattern.name].texture
  }

  updateMode = (newMode: Mode = this.mode) => {
    this.mode = newMode
    this.modeContainers[Mode.solo].visible = this.mode === Mode.solo
    this.modeContainers[Mode.duo].visible = this.mode === Mode.duo
  }

  updateTheme = (newTheme: Theme = this.theme) => {
    this.theme = newTheme

    // SOLO

    this.heartSprites[Mode.solo].texture = this.loader.resources[
      this.heartTextures[Mode.solo][this.theme]
    ].texture

    // DUO

    this.heartSprites[Mode.duo].forEach((sprite, i) => {
      sprite.texture = this.loader.resources[this.heartTextures[Mode.duo][this.theme][i]].texture
    })
  }

  takeIntermediatePicture = () => {
    this.isIntermediatePictureTaken = true
    this.webcamSprites[Mode.duo][1].alpha = 1

    // take a snapshoot of first webcam sprite
    const renderer = PIXI.autoDetectRenderer()
    const webcamClone = new PIXI.Sprite(this.webcamSprites[Mode.duo][0].texture.clone())
    const renderTexture = PIXI.RenderTexture.create({
      width: webcamClone.width,
      height: webcamClone.height,
    })
    renderer.render(webcamClone, renderTexture)
    const image = renderer.plugins.extract.base64(renderTexture)
    this.webcamSprites[Mode.duo][0].texture = PIXI.Texture.from(image)

    // document.body.appendChild(image)
  }

  takeFinalPicture = () => {
    const texture = this.app.renderer.generateTexture(
      this.app.stage,
      PIXI.SCALE_MODES.LINEAR,
      1,
      new PIXI.Rectangle(0, 0, this.app.view.width, this.app.view.height)
    )
    this.reset()
    return this.app.renderer.plugins.extract.base64(texture)
    // document.body.appendChild(image)
  }
}
