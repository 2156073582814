import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { gsap, Power1, Power2 } from 'gsap'

import MainBackground from '../../components/MainBackground'
import withMemo from '../../decorators/withMemo'
import { sizes } from '../../theme'
import { useWindowSize } from '../../hooks/useWindowSize'
import { getAddThisLinks } from '../../utils/AddThis'
import { Link } from '../../components'

import * as SC from './styled'

export type FinalScreenTemplateProps = {
  shareUrl?: string
  back?: string
  imageRenditions?: {
    portrait: string
    landscape: string
  }
  onRestartClick: () => void
  isSharing?: boolean
}

const FinalScreenTemplate: FC<FinalScreenTemplateProps> = ({
  shareUrl,
  imageRenditions,
  back = 'BACK',
  isSharing = false,
  onRestartClick,
}) => {
  const $loader = useRef(null)
  const $loaderBackground = useRef(null)
  const $loaderImage = useRef(null)

  // state
  const [sharePopup, setSharePopup] = useState(false)
  const [isImageLoaded, setImageLoaded] = useState(false)

  const { width } = useWindowSize()

  // memo

  const downloadLink = useMemo(() => {
    if (!imageRenditions) {
      return '#'
    } else {
      return width < sizes.tablet ? imageRenditions.portrait : imageRenditions.landscape
    }
  }, [imageRenditions, width])

  const shareLinks = useMemo(() => {
    if (shareUrl && process.env.ADDTHIS_ACCOUNT) {
      return getAddThisLinks(process.env.ADDTHIS_ACCOUNT, shareUrl)
    }
    return null
  }, [shareUrl])

  // animation

  const animateLoaderOut = useCallback(() => {
    const tl = gsap.timeline()

    tl.set($loader.current, {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    })
      .to($loaderImage.current, {
        duration: 0.2,
        opacity: 0,
        ease: Power1.easeOut,
      })
      .to(
        $loaderBackground.current,
        {
          scaleX: 0,
          duration: 0.5,
          ease: Power2.easeInOut,
        },
        0.2
      )
  }, [])

  // handlers

  const handleShareClick = useCallback(() => {
    if (navigator.share && width < sizes.desktop) {
      navigator.share({
        url: shareUrl,
        title: 'Make your own Balenciaga Valentine',
        text: '',
      })
    } else {
      setSharePopup(true)
    }
  }, [shareUrl, width])

  const handleOnImageLoad = useCallback(() => {
    setImageLoaded(true)
    animateLoaderOut()
  }, [animateLoaderOut])

  // return

  return (
    <SC.FinalScreenTemplate>
      <MainBackground />
      {imageRenditions && (
        <SC.Photo isImageLoaded={isImageLoaded}>
          <img src={imageRenditions.portrait} alt="Photo" onLoad={handleOnImageLoad} />
        </SC.Photo>
      )}
      <SC.PhotoLoader ref={$loader}>
        <SC.PhotoLoaderRatioBox>
          <SC.PhotoLoaderRatioContent>
            <SC.PhotoLoaderBackground ref={$loaderBackground} />
            <img src={'/static/images/loader.gif'} alt={'loader'} ref={$loaderImage} />
          </SC.PhotoLoaderRatioContent>
        </SC.PhotoLoaderRatioBox>
      </SC.PhotoLoader>

      <SC.ButtonsContainer>
        <SC.Btn text={back} onClick={onRestartClick} />
        {!isSharing && <SC.Btn text="SHARE" onClick={handleShareClick} />}
        <SC.SaveLink link={downloadLink} download="love-balenciaga" target={'_self'}>
          <SC.Btn text="SAVE" />
        </SC.SaveLink>
        <Link link="https://www.balenciaga.com/us/all/i-love-u">
          <SC.Btn text="SHOP" />
        </Link>
      </SC.ButtonsContainer>

      {sharePopup && shareLinks && (
        <>
          <SC.Lightbox onClick={() => setSharePopup(false)} />
          <SC.Popup>
            {shareLinks.map((shareLink) => (
              <SC.Link key={shareLink.text} link={shareLink.link}>
                {shareLink.text}
              </SC.Link>
            ))}
          </SC.Popup>
        </>
      )}
    </SC.FinalScreenTemplate>
  )
}

export default withMemo(FinalScreenTemplate)
