import styled from 'styled-components'

import { ActionButton } from '../../components'
import { textStyles, mq, sizes } from '../../theme'

export const DisclaimerTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: red;
  width: 100%;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);

  @media ${mq(sizes.tablet)} {
    padding: 50px 0;
  }
`

export const CentralSection = styled.div`
  width: 90%;
  max-width: 800px;
  padding: 0 10px;
  max-height: 75vh;
  overflow: auto;
  margin-bottom: 6vh;
`

export const Text = styled.div`
  ${textStyles.disclaimer};
  color: white;
  p {
    margin-bottom: 12px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`

export const BackButton = styled(ActionButton)`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  ${textStyles.button}
  padding: 14px 23px;
`
