import styled, { css } from 'styled-components'

import Link from '../Link'

const styles = (props: any) => css`
  display: inline-block;
  position: relative;
  fill: ${props.color};
  & svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: ${props.color};
  }
  & *[fill]:not([fill='none']):not(.no-fill) {
    fill: ${props.color};
  }
  & *[stroke]:not([stroke='none']):not(.no-stroke) {
    stroke: ${props.color};
  }
`

export const IconSpan = styled.span`
  ${(props) => styles(props)}
`

export const IconLink = styled(Link)``

export const IconLinkWrapper = styled.div`
  cursor: pointer;
  ${IconLink} {
    ${(props) => styles(props)}
  }
`
