import styled from 'styled-components'

import { mq, sizes } from '../../theme'
import Icon from '../Icon'

export const BottomMenu = styled.div`
  display: flex;
  align-items: center;
`

export const Button = styled.div`
  background-color: white;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  &.camera {
    margin-right: 20px;
    margin-left: 20px;
    height: 80px;
    width: 80px;
    @media ${mq(sizes.tablet)} {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
`

export const LeftArrow = styled(Icon)`
  margin-right: 7px;
  width: 20px;
`

export const RightArrow = styled(Icon)`
  margin-left: 5px;
  width: 20px;
`

export const Camera = styled(Icon)`
  width: 45px;
`
