import React, { FC, useEffect, useRef } from 'react'
import cx from 'classnames'

import { withMemo } from '../../decorators'
import { Mode, Theme } from '../../types/global'

import { PixiCanvas } from './PixiCanvas'
import * as SC from './styled'

type PhotoCanvasProps = {
  className?: string
  webcamVideoElement?: HTMLVideoElement
  currentTheme: Theme
  currentMode: Mode
}

export const pixiCanvas = typeof window !== `undefined` ? new PixiCanvas() : null

const PhotoCanvas: FC<PhotoCanvasProps> = (props) => {
  const { className, webcamVideoElement, currentTheme, currentMode } = props

  const $canvasWrapper = useRef<HTMLDivElement>(null)

  // EFFECTS

  // update webcam video element
  useEffect(() => {
    if (webcamVideoElement) {
      pixiCanvas?.setWebcamVideo(webcamVideoElement)
    }
  }, [webcamVideoElement])

  // update theme
  useEffect(() => {
    pixiCanvas?.updateTheme(currentTheme)
  }, [currentTheme])

  // update mode
  useEffect(() => {
    pixiCanvas?.updateMode(currentMode)
  }, [currentMode])

  // start / stop application
  useEffect(() => {
    $canvasWrapper.current?.appendChild(pixiCanvas?.app.view)
    pixiCanvas?.app.start()
    return () => {
      pixiCanvas?.app.stop()
    }
  }, [])

  return <SC.CanvasWrapper className={cx(className)} ref={$canvasWrapper} />
}

export default withMemo(PhotoCanvas)
