import styled from 'styled-components'

import { textStyles } from '../../theme'

export const Lightbox = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
`

export const RedirectToSafariPopup = styled.div`
  position: relative;
  background-color: white;
  margin: auto;
  width: 300px;
  max-width: 85%;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  padding: 35px;
  border-radius: 6px;
  text-align: center;
  ${textStyles.safariPopup}
`
