import React, { FC, useCallback } from 'react'

import { withMemo } from '../../decorators'

import * as SC from './styled'

type ActionButtonProps = {
  className?: string
  id?: string
  text: string
  isActive?: boolean
  onClick?: (id?: string) => void
}

const ActionButton: FC<ActionButtonProps> = ({
  id,
  text,
  isActive = false,
  onClick = null,
  className,
}) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(id)
    }
  }, [id, onClick])

  return (
    <SC.ActionButton className={className} onClick={handleOnClick} isActive={isActive}>
      {text}
    </SC.ActionButton>
  )
}

export default withMemo(ActionButton)
