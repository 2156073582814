import styled from 'styled-components'

import {
  BottomMenu as BottomMenuComponent,
  Icon,
  PhotoCanvas,
  Webcam as WebcamComponent,
} from '../../components'
import { colors, textStyles, mq, sizes } from '../../theme'

export const CaptureTemplate = styled.div`
  background-color: ${colors.pureWhite};
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
`

export const Text = styled.p`
  ${textStyles.button}
`

export const CountdownBanner = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 90px;
  background-color: white;
  display: flex;
  visibility: hidden;

  &.active {
    visibility: visible;
  }
`

export const Countdown = styled.div`
  width: 75%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  max-width: 350px;
`

export const Count = styled.div`
  ${textStyles.count};
  transform: translateY(9%);
  color: #aaaaaa;
  &.active {
    color: black;
  }
`

export const CameraIcon = styled(Icon)`
  color: #aaaaaa;
  width: 50px;
  &.active {
    color: black;
  }
`

export const TopMenu = styled.div`
  position: absolute;
  top: 20px;
  right: 25px;
  display: flex;
  align-items: center;
`

export const Heart = styled(Icon)``

export const DoubleHearts = styled(Icon)``

export const Button = styled.div`
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  z-index: 1;
  text-align: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  cursor: pointer;

  &.heart {
    margin-right: 9px;
  }

  ${Heart} {
    margin: auto;
    width: 23.7px;
    color: #aaaaaa;
  }

  ${DoubleHearts} {
    margin: auto;
    width: 25px;
    color: #aaaaaa;
  }

  &.active {
    height: 50px;
    width: 50px;

    ${Heart} {
      width: 31px;
      color: black;
    }

    ${DoubleHearts} {
      width: 33px;
      color: black;
    }
  }
`

export const BottomMenu = styled(BottomMenuComponent)`
  z-index: 11;
  position: absolute;
  left: 50%;
  bottom: 5vh;
  transform: translate(-50%);

  @media ${mq(sizes.tablet)} {
    bottom: 30px;
  }
`

export const Canvas = styled(PhotoCanvas)`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: calc(50% - 3vh);
  width: 106%;
  max-width: 75vh;

  @media ${mq(sizes.tablet)} {
    width: 100%;
    top: calc(50% - 4.5vh);
    max-width: 80vh;
  }
`

export const Webcam = styled(WebcamComponent)`
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 120px;
`
