import styled from 'styled-components'

import { colors } from '../../theme'

export const ActionButton = styled.button<{ isActive: boolean }>`
  font-size: 1.5rem;
  background-color: ${({ isActive }) => (isActive ? colors.red : colors.pureWhite)};
  color: ${({ isActive }) => (isActive ? colors.pureWhite : colors.pureBlack)};
  padding: 10px 15px;
  cursor: pointer;
`
