import styled, { css } from 'styled-components'

import { ActionButton, Link as LinkComponent } from '../../components'
import { colors, textStyles, mq, sizes, aspectRatio } from '../../theme'

export const FinalScreenTemplate = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.pureWhite};
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
`

const photoStyles = css`
  position: absolute;
  top: calc(50% - 4.5vh);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: calc(67vh * 3 / 4);
  border: 8px solid white;
  box-shadow: 15px 15px 20px 0 rgba(0, 0, 0, 0.5);
  @media ${mq(sizes.tablet)} {
    max-width: calc(72vh * 3 / 4);
  }
`

export const Photo = styled.picture<{ isImageLoaded: boolean }>`
  display: ${({ isImageLoaded }) => (isImageLoaded ? 'block' : 'none')};
  ${photoStyles}

  img {
    width: 100%;
  }
`

export const PhotoLoader = styled.div`
  ${photoStyles};
  pointer-events: none;
  background-color: white;
`

export const PhotoLoaderRatioBox = styled.div`
  ${aspectRatio(3, 4)}
`

export const PhotoLoaderRatioContent = styled.div`
  display: flex;
  img {
    position: relative;
    margin: auto;
    width: 80px;
  }
`

export const PhotoLoaderBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  transform-origin: 100% 50%;
`

export const ButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 4vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 97;

  @media ${mq(sizes.tablet)} {
    bottom: 40px;
  }
`

export const Btn = styled(ActionButton)`
  width: 100px;
  height: 43px;
  border-radius: 6px;
  ${textStyles.button}
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin: 0 6px;
`

export const SaveLink = styled(LinkComponent)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`

export const Popup = styled.div`
  background-color: white;
  height: auto;
  position: absolute;
  width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  padding: 35px 50px;
  border-radius: 6px;
  z-index: 99;
`

export const Link = styled(LinkComponent)`
  ${textStyles.button};
  padding: 6px 0 3px 0;
  color: black;
  text-transform: uppercase;
`

export const Lightbox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 98;
  background-color: black;
  opacity: 50%;
`
