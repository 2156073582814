import React, { FC } from 'react'
import styled from 'styled-components'

import { withMemo } from '../../decorators'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const CheckIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<{ checked: boolean; hasError: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ hasError }) => (hasError ? 'red' : 'white')};
  transition: all 150ms;

  ${CheckIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    stroke: black;
  }
`
export type CheckboxProps = {
  className?: string
  checked: boolean
  hasError: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: FC<CheckboxProps> = ({ className, checked, hasError, ...rest }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...rest} />
    <StyledCheckbox checked={checked} hasError={hasError}>
      <CheckIcon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </CheckIcon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default withMemo(Checkbox)
