export const getAddThisLinks = (addThisAccount: string, url: string) => {
  const addThisUrl = 'http://api.addthis.com/oexchange/0.8'
  const addThisEndPoint = '/forward/SERVICE_CODE/offer'

  const addThisParams = {
    url,
    pubid: addThisAccount,
    // optional
    title: '',
    description: '',
    screenshot: '',
    email_template: '',
    ct: '',
  }

  const generateAddThisUrl = (serviceCode: string) =>
    `${addThisUrl}${addThisEndPoint.replace('SERVICE_CODE', serviceCode)}?${Object.entries(
      addThisParams
    )
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join('&')}`

  return [
    {
      link: generateAddThisUrl('twitter'),
      text: 'twitter',
    },
    {
      link: generateAddThisUrl('facebook'),
      text: 'facebook',
    },
    {
      link: generateAddThisUrl('wechat'),
      text: 'wechat',
    },
    {
      link: generateAddThisUrl('sinaweibo'),
      text: 'weibo',
    },
  ]
}
