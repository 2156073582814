import path from 'path'
import { Actions } from 'gatsby'

import type { Route } from '../../router/routes'
import router from '../../router'

export default (graphql: any, actions: Actions, route: Route) => {
  const { createPage } = actions
  const template = path.resolve('src/containers/Picture/index.tsx')

  return createPage({
    path: router(route.path),
    component: template,
    context: {},
  })
}
