import React, { FC, useEffect, useState, useCallback } from 'react'
import { useParams } from '@reach/router'

import withMemo from '../../decorators/withMemo'
import { FinalScreenTemplate } from '../index'

type PictureTemplateProps = {
  withoutSlug?: boolean
  onRestartClick: () => void
  path?: string
}

const PictureTemplate: FC<PictureTemplateProps> = ({ onRestartClick, withoutSlug = false }) => {
  const urlPrams = useParams()
  const [slug, setSlug] = useState<string | null>(null)

  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams
    const param = params?.get('slug') ?? null
    setSlug(param ?? null)
  }, [])

  const renderTemplate = useCallback(
    (slugParam: string) => (
      <FinalScreenTemplate
        onRestartClick={onRestartClick}
        back={'TRY IT'}
        imageRenditions={{
          portrait: `/share/${slugParam}/portrait/love-balenciaga.jpg`,
          landscape: `/share/${slugParam}/landscape/love-balenciaga.jpg`,
        }}
        isSharing
      />
    ),
    [onRestartClick]
  )

  return (
    <>
      {withoutSlug && slug && renderTemplate(slug)}
      {!withoutSlug && renderTemplate(urlPrams.slug)}
    </>
  )
}

export default withMemo(PictureTemplate)
