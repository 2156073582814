import * as controllers from '../Http/Controllers'

export type Route = {
  component: any
  path: string
  name: string
}

export type Routes = {
  root: Route
  debug: Route
  disclaimer: Route
  picture: Route
}

const routes: Routes = {
  root: {
    component: controllers.RootController,
    path: '/',
    name: 'root',
  },
  debug: {
    component: controllers.DebugController,
    path: '/debug',
    name: 'debug',
  },
  disclaimer: {
    component: controllers.DisclaimerController,
    path: '/disclaimer',
    name: 'disclaimer',
  },
  picture: {
    component: controllers.PictureController,
    path: '/picture',
    name: 'picture',
  },
}

export default routes
