import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { gsap, Power2, Power3 } from 'gsap'

import MainBackground from '../../components/MainBackground'
import withMemo from '../../decorators/withMemo'
import routes from '../../router/routes'
import { Link } from '../../components'
import Checkbox, { CheckboxProps } from '../../components/Checkbox'

import * as SC from './styled'

const popup = '/static/images/popupTitle.png'

export type HomepageTemplateProps = {
  onStartClick: () => void
}

const HomepageTemplate: FC<HomepageTemplateProps> = ({ onStartClick }) => {
  // ref

  const $overlay = useRef(null)
  const $popupImg = useRef(null)

  // state

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [hasDisclaimerError, setHasDisclaimerError] = useState(false)

  // animations

  const animateIn = useCallback(() => {
    const tl = gsap.timeline({
      delay: 0.15,
    })

    tl.to($popupImg.current, {
      duration: 1.2,
      y: 0,
      ease: Power3.easeOut,
    })
      .to(
        $popupImg.current,
        {
          duration: 0.4,
          opacity: 1,
        },
        0.15
      )
      .to(
        $overlay.current,
        {
          duration: 0.7,
          scaleY: 0,
          ease: Power2.easeInOut,
        },
        '-=0.2'
      )
  }, [])

  // handlers

  const handleOnCheckboxChange: CheckboxProps['onChange'] = useCallback((e) => {
    setIsCheckboxChecked(e.target.checked)
    if (e.target.checked) {
      setHasDisclaimerError(false)
    }
  }, [])

  const handleOnStartClick = useCallback(() => {
    if (!isCheckboxChecked) {
      setHasDisclaimerError(true)
    } else {
      onStartClick()
    }
  }, [isCheckboxChecked, onStartClick])

  // effect

  useEffect(() => {
    animateIn()
  }, [animateIn])

  // return

  return (
    <SC.HomeTemplate>
      <MainBackground />

      <SC.PopupImg ref={$popupImg} src={popup} />
      <SC.StartButton text="LET'S GET STARTED" onClick={handleOnStartClick} />

      <SC.BottomGradient />

      <SC.Disclaimer hasError={hasDisclaimerError}>
        <Checkbox
          checked={isCheckboxChecked}
          onChange={handleOnCheckboxChange}
          hasError={hasDisclaimerError}
        />
        <p>
          I agree that Balenciaga collects and uses my personal information.{' '}
          <Link route={routes.disclaimer.path}>More information</Link>
        </p>
      </SC.Disclaimer>
      <SC.Overlay ref={$overlay} />
    </SC.HomeTemplate>
  )
}

export default withMemo(HomepageTemplate)
