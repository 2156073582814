import React, { FC, useCallback } from 'react'

import MainBackground from '../../components/MainBackground'
import withMemo from '../../decorators/withMemo'
import { Link } from '../../components'
import routes from '../../router/routes'

import * as SC from './styled'

export type DisclaimerTemplateProps = any

const HomepageTemplate: FC<DisclaimerTemplateProps> = () => {
  return (
    <SC.DisclaimerTemplate>
      <SC.CentralSection>
        <SC.Text>
          <p>
            With your consent, your information is collected by Balenciaga to enable you to access
            the services of the website. The pictures taken will only be kept for the time necessary
            for their sharing, their save, and their technical processing. Privacy laws may grant
            you certain rights such as the right to access, delete, modify and rectify your data, or
            to restrict or object to processing, as well as the right to data portability. You can
            also lodge a complaint with your competent regulator. You can withdraw your consent. For
            further information regarding our privacy practices and your rights, please consult our
            privacy policy.
          </p>
          <p dir="rtl">
            بموافقتك، يتم جمع معلوماتك من قِبل Balenciaga لتمكينك من الوصول إلى خدمات الموقع. سيتم
            الاحتفاظ بالصور الملتقطة فقط للوقت اللازم لمشاركتها وحفظها ومعالجتها التقنية. قد تمنحك
            قوانين الخصوصية حقوقًا معينة مثل الحق في الوصول إلى بياناتك وحذفها وتعديلها وتصحيحها، أو
            تقييد المعالجة أو الاعتراض عليها، وكذلك الحق في إمكانية نقل البيانات. يمكنك أيضًا تقديم
            شكوى إلى المراقب المختص. يمكنك سحب موافقتك. لمزيد من المعلومات حول ممارسات الخصوصية
            ;لدينا وحقوقك، يرجى الرجوع إلى سياسة الخصوصية الخاصة بنا.
          </p>
          <p>
            S vaším souhlasem společnost Balenciaga shromažďuje vaše osobní údaje, aby vám mohla
            poskytnout přístup ke službám na své internetové stránce. Pořízené fotografie budou
            uchovány pouze po dobu nutnou ke sdílení, uložení a technickému zpracování. Zákony na
            ochranu osobních údajů vám mohou přiznávat jistá práva, například právo na přístup k
            osobním údajům, právo na jejich smazání, úpravu či opravu, právo omezit jejich
            zpracování či vůči němu vznést námitku a rovněž právo na přenositelnost údajů. Můžete
            také vznést stížnost o příslušného dozorového úřadu. Souhlas se zpracováním můžete
            odvolat. Další informace ohledně našich postupů při ochraně osobních údajů a vašich práv
            jsou obsaženy v našich zásadách ochrany osobních údajů.
          </p>
          <p>
            Uw gegevens worden met uw toestemming verzameld door Balenciaga om u toegang te verlenen
            tot de diensten van de website. De genomen foto&apos;s worden alleen bewaard gedurende
            de tijd die nodig is om ze te delen, op te slaan en technisch te verwerken. De
            privacywetten kunnen u bepaalde rechten verlenen zoals het recht om uw gegevens in te
            zien, ze te verwijderen, te wijzigen en te corrigeren, om de verwerking ervan te
            beperken of er bezwaar tegen te maken. Daarnaast heeft u het recht op overdraagbaarheid
            van uw gegevens. Ook kunt u een klacht indienen bij de bevoegde toezichthouder of uw
            toestemming intrekken .Raadpleeg ons privacybeleid voor meer informatie over onze
            praktijken met betrekking tot uw persoonsgegevens en uw rechten.
          </p>

          <p>
            Avec votre consentement, Balenciaga collecte vos informations afin de vous permettre de
            bénéficier des services fournis par le site Internet. Les photos prises ne seront
            conservées que pendant le délai nécessaire à leur partage, leur enregistrement et leur
            traitement technique. La législation en vigueur en matière de protection des données
            peut vous octroyer certains droits tels que le droit d’accéder à vos données, le droit
            de demander leur effacement ou leur rectification ou le droit de limiter leur traitement
            ou de vous y opposer ainsi que le droit d’obtenir leur portabilité. Vous pouvez
            également introduire une réclamation auprès de votre autorité de contrôle compétente.
            Vous pouvez retirer ce consentement. Pour en savoir plus concernant nos pratiques en
            matière de protection des données et vos droits à ce titre, merci de consulter notre
            politique de confidentialité.
          </p>

          <p>
            Mit Ihrem Einverständnis werden Ihre Daten von Balenciaga erfasst, um Ihnen den Zugriff
            auf die Dienste dieser Webseite zu ermöglichen. Bilder werden nur für die Dauer
            aufbewahrt, die für ihre Übermittlung, Speicherung und technische Verarbeitung
            erforderlich ist. Die Datenschutzrechte ermöglichen es Ihnen, auf Ihre Daten
            zuzugreifen, sie zu löschen, verändern oder zu aktualisieren; Sie haben auch die
            Möglichkeit, einer Verarbeitung Ihrer Daten zu widersprechen oder diese abzulehnen sowie
            das Recht auf Datenübertragbarkeit. Sie können bei der für Sie zuständigen
            Aufsichtsbehörde Beschwerde einlegen und Ihre Zustimmung zurückziehen. Für weitere
            Informationen hinsichtlich unseres Umgangs mit dem Datenschutz und Ihrer Rechte lesen
            Sie bitte unsere Datenschutzrichtlinien.
          </p>

          <p>
            A Balenciaga a beleegyezésével gyűjti az adatait, hogy hozzá tudjon férni a webhely
            szolgáltatásaihoz. A készített képeket csak a megosztásukhoz, elmentésükhöz és a
            technikai feldolgozásukhoz szükséges ideig tároljuk. Az adatvédelmi törvények
            biztosíthatnak bizonyos jogokat, például az adatai hozzáféréséhez, törléséhez,
            módosításához vagy helyesbítéséhez való jogot, a feldolgozás korlátozásának és
            ellenzésének jogát, valamint az adathordozhatósághoz való jogot. Az illetékes szabályozó
            hatóságnál tud panaszt tenni. Visszavonhatja a beleegyezését. Az adatvédelmi
            gyakorlatokkal és a jogaival kapcsolatos további információkért olvassa el az
            Adatvédelmi szabályzatunkat.
          </p>

          <p>
            Con il tuo consenso, le tue informazioni vengono raccolte da Balenciaga per consentirti
            di accedere ai servizi del sito internet. Le foto scattate verranno conservate solo per
            il tempo necessario alla condivisione, salvataggio ed elaborazione tecnica delle stesse.
            Le leggi sulla privacy possono concederti determinati diritti come il diritto di
            accedere, eliminare, modificare e rettificare i tuoi dati o limitare o opporti al
            trattamento degli stessi, come pure il diritto alla portabilità dei dati. Puoi anche
            presentare un reclamo alla autorità di regolamentazione competente per il tuo Paese.
            Puoi revocare il tuo consenso. Per ulteriori informazioni sulle nostre pratiche sulla
            privacy e sui tuoi diritti, consulta la nostra politica sulla privacy.
          </p>

          <p>
            お客様の同意を得て、お客様の情報は、お客様がウェブサイトのサービスにアクセスできるようにするためバレンシアガによって収集されます。撮影された写真は、共有、保存、技術的処理に必要な期間のみ保存されます。個人情報保護法は、お客様のデータへのアクセス、削除、変更、修正や、処理の制限と異議申し立て、データポータビリティの権利など、お客様に一定の権利を付与する場合があります。また、お客様は、所轄の規制当局に苦情を申し立てることができます。同意を撤回することもできます。当社のプライバシー慣行とお客様の権利に関する詳細については、当社のプライバシーポリシーを参照してください。
          </p>

          <p>
            귀하의 동의 하에, 발렌시아가는 귀하가 웹사이트 서비스에 접근할 수 있도록 귀하의 정보를
            수집합니다. 촬영한 사진은 공유, 저장, 및 기술적 처리에 필요한 기간 동안만 보관됩니다.
            개인정보보호법에 따라 귀하의 데이터를 열람, 삭제, 수정 및 정정하거나, 처리를 제한하거나
            처리에 반대할 권리 및 데이터 휴대 권리와 같은 특정 권리를 귀하에게 허여할 수 있습니다.
            또한 관할 규제기관에 불만을 제기하실 수도 있습니다. 귀하는 동의를 철회하실 수 있습니다.
            당사의 개인정보 보호 기준 및 귀하의 권리에 관한 보다 자세한 정보는 당사 개인정보
            취급방침을 참조하시기 바랍니다.
          </p>

          <p>
            Dengan kebenaran anda, maklumat anda dikumpulkan oleh Balenciaga untuk membolehkan anda
            untuk mengakses perkhidmatan laman web tersebut. Gambar yang diambil akan hanya disimpan
            untuk masa yang diperlukan untuk perkongsian mereka, penyimpanan mereka dan pemprosesan
            teknikal mereka. Undang-undang privasi memberikan anda hak tertentu seperti hak untuk
            mengakses, memadam, mengubah suai dan membetulkan data anda atau untuk menyekat atau
            membantah kepada pemprosesan, dan juga hak kepada kemudahalihan data. Anda boleh juga
            membuat aduan dengan pengawal atur kompeten anda. Anda boleh menarik balik kebenaran
            anda. Untuk mendapatkan maklumat lanjut berkenaan amalan privasi kami dan hak anda, sila
            rujuk dasar privasi kita.
          </p>

          <p>
            Com o seu consentimento, as suas informações são recolhidas pela Balenciaga para lhe
            permitir o acesso aos serviços do site da Web. As fotos tiradas serão somente
            conservadas pelo tempo necessário para a sua partilha, a sua gravação e o seu tratamento
            técnico. As leis da privacidade podem conceder-lhe determinados direitos, tais como o
            direito de aceder, apagar, modificar e retificar os seus dados ou de restringir ou de se
            opor ao tratamento, bem como o direito à portabilidade dos dados. Pode também efetuar
            uma reclamação junto da sua autoridade de controlo. Pode retirar o seu consentimento.
            Para obter mais informações sobre as nossas práticas de privacidade e sobre os seus
            direitos, deve consultar a nossa política de privacidade.
          </p>

          <p>
            Чтобы Вы могли получить доступ к услугам веб-сайта, «Balenciaga» с Вашего согласия
            собирает Вашу информацию. Сделанные Вами фотографии будут храниться только в течение
            времени, необходимого для их публикации, сохранения и технической обработки. Нормы
            права, охраняющие неприкосновенность частной жизни, могут предоставить Вам определенные
            права, такие как право доступа, удаления, изменения и исправления Ваших данных, право
            ограничивать или возражать против их обработки, а также право на переносимость данных.
            Вы также можете подать жалобу в компетентный регулирующий орган. Вы можете отозвать свое
            согласие. Для получения дополнительной информации о нашей политике конфиденциальности и
            о Ваших правах, ознакомтесь, пожалуйста, с нашей политикой конфиденциальности.
          </p>

          <p>
            Balenciaga经您同意收集您的信息，以便您能够访问网站的服务。所拍摄的照片将仅在共享、保存和技术处理所需的时间内保留。隐私法可能授予您某些权利，例如访问、删除、修改和更正您数据的权利，限制或反对处理的权利，以及数据可携权。您还可以向主管监管机构提出申诉。您可以撤回同意。有关我们隐私做法和您权利的更多信息，请查阅我们的隐私政策。
          </p>

          <p>
            Con su consentimiento, Balenciaga recoge su información para permitirle acceder a los
            servicios del sitio web. Las imágenes que se tomen se conservarán únicamente durante el
            tiempo necesario para compartirlas, guardarlas y para su procesamiento técnico. Las
            leyes de privacidad pueden darle ciertos derechos tales como el derecho de acceder,
            suprimir, modificar y rectificar sus datos, o de limitar u oponerse al tratamiento, así
            como el derecho a la portabilidad de los datos. También puede presentar una queja con su
            ente regulador competente. Puede retirar su consentimiento. Para obtener más información
            sobre nuestras prácticas de privacidad y sus derechos, por favor consulte nuestra
            política de privacidad.
          </p>

          <p>
            ภายใต้ความยินยอมของท่าน Balenciaga
            จะเก็บรวบรวมข้อมูลของท่านเพื่อให้ท่านสามารถเข้าถึงบริการต่าง ๆ ของเว็บไซต์ได้
            ภาพที่ถ่ายจะเก็บไว้เฉพาะในช่วงเวลาที่จำเป็นสำหรับการแบ่งปัน บันทึก
            และการประมวลผลทางเทคนิคเท่านั้น กฎหมายคุ้มครองข้อมูลส่วนบุคคลอาจให้สิทธิบางประการแก่ท่าน
            เช่น สิทธิการเข้าถึงข้อมูล ลบ ปรับเปลี่ยน และแก้ไขข้อมูล หรือเพื่อจำกัด
            หรือคัดค้านการประมวลผลตลอดจนสิทธิในการเคลื่อนย้ายข้อมูล นอกจากนี้
            ท่านยังสามารถยื่นเรื่องร้องเรียนต่อหน่วยงานกำกับดูแลที่รับผิดชอบ
            ท่านสามารถถอนความยินยอมของท่านได้
            สำหรับข้อมูลเพิ่มเติมเกี่ยวกับหลักปฏิบัติด้านความเป็นส่วนตัวและสิทธิต่าง ๆ ของท่าน
            โปรดอ่านนโยบายความเป็นส่วนตัวของเรา
          </p>

          <p>
            Rızanız doğrultusunda bilgileriniz, web sitesinin sunduğu hizmetlere erişmenizi mümkün
            kılmak için Balenciaga tarafından toplanır. Çekilen resimler yalnızca paylaşılmaları,
            kaydedilmeleri ve teknik işlemleri için gereken süre boyunca saklanacaktır. Mahremiyet
            hukuku size verilerinize erişme, verileri silme, değiştirme ve düzeltme ya da işlemeyi
            kısıtlama ya da buna itiraz etme hakkı ile veri taşınabilirliği hakkı gibi belirli
            haklar verebilir. Ayrıca yetkili düzenleyicinize şikayette bulunabilirsiniz. Rızanızı
            geri alabilirsiniz. Gizlilik uygulamalarımız ve haklarınız hakkında daha fazla bilgi
            için lütfen gizlilik politikamıza bakınız.
          </p>

          <p>
            Với sự đồng thuận của bạn, thì thông tin của bạn sẽ được Balenciaga thu thập để cho phép
            cho bạn truy cập các dịch vụ của trang web. Những hình ảnh đã chụp sẽ chỉ được lưu giữ
            cho khoảng thời gian cần thiết cho việc chia sẻ, lưu, và xử lý kỹ thuật của chúng. Luật
            về quyền riêng tư có thể cấp cho bạn những quyền hạn nào đó chẳng hạn như quyền truy
            cập, xóa, sửa đổi và chỉnh sửa dữ liệu của bạn, hoặc hạn chế hay phản đối việc xử lý,
            cũng như quyền chuyển đổi dữ liệu. Bạn cũng có thể gửi đơn khiếu nại với cơ quan quản lý
            có thẩm quyền của mình. Bạn cũng có thể rút lại sự đồng thuận của mình. Để biết thêm
            thông tin về các thông lệ thực hành về quyền riêng tư của chúng tôi và quyền hạn của
            bạn, vui lòng tham vấn chính sách về quyền riêng tư của chúng tôi.
          </p>

          <p>
            經您同意，巴黎世家會收集您的個人資料，以便您享有網站的服務。所拍攝的照片僅在共享、保存和技術處理所需的時間內保存。個人資料隱私法授予您某些權利，例如對您的數據進行訪問、刪除、修改和更正，或限制或反對數據處理，以及數據轉移的權利。您可向主管監管機構投訴。您可撤回您的同意。有關我們的隱私慣例和您的權利的更多訊息，請查詢我們的隱私政策。
          </p>

          <p>
            Με τη συγκατάθεσή σας, οι πληροφορίες σας συλλέγονται από την Balenciaga με σκοπό να σας
            επιτραπεί η πρόσβαση στις υπηρεσίες του ιστότοπου. Οι φωτογραφίες που λαμβάνονται θα
            διατηρούνται μόνο για όσο χρονικό διάστημα απαιτείται για την ανταλλαγή, τη αποθήκευση
            και την τεχνική επεξεργασία τους. Οι νόμοι περί απορρήτου μπορεί να σας παρέχουν
            ορισμένα δικαιώματα, όπως το δικαίωμα πρόσβασης, διαγραφής, τροποποίησης και διόρθωσης
            των δεδομένων σας, ή το δικαίωμα περιορισμού ή αντίταξης στην επεξεργασία, καθώς και το
            δικαίωμα στη φορητότητα των δεδομένων. Μπορείτε επίσης να υποβάλλετε καταγγελία στον
            αρμόδιο ρυθμιστικό φορέα. Μπορείτε να ανακαλέσετε τη συγκατάθεσή σας. Για περαιτέρω
            πληροφορίες σχετικά με τις πρακτικές και τα δικαιώματά σας όσον αφορά το απόρρητο,
            παρακαλούμε να συμβουλευτείτε την πολιτική απορρήτου μας.
          </p>
        </SC.Text>
      </SC.CentralSection>
      <Link route={routes.root.path}>
        <SC.BackButton text={'BACK'} />
      </Link>
    </SC.DisclaimerTemplate>
  )
}

export default withMemo(HomepageTemplate)
