import styled, { keyframes } from 'styled-components'

const image = '/static/images/mainBackground/background.png'
const gif1 = '/static/images/mainBackground/bow.gif'
const gif2 = '/static/images/mainBackground/sparkles.gif'
const gif3 = '/static/images/mainBackground/hearts.gif'

import { mq, sizes } from '../../theme'

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: red;
  background-image: url(${image});
  background-repeat: repeat;
  left: 0;
  z-index: 0;
  background-size: 50%;
  @media ${mq(sizes.tablet)} {
    background-size: 350px;
  }
`

const backgroundPositionKeyframesMobile = keyframes`
 from {
   background-position-y: 0;
 }
  to {
   background-position-y: -50vw;
 }
`

const backgroundPositionKeyframesTablet = keyframes` 
  from {
   background-position-y: 0;
 }
  to {
   background-position-y: -350px;
 }
`

const GifWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-repeat: repeat;
  background-size: 50%;
  background-position: 50% 0;
  left: 0;
  z-index: 0;
  animation: ${backgroundPositionKeyframesMobile} 8s linear infinite;
  @media ${mq(sizes.tablet)} {
    animation: ${backgroundPositionKeyframesTablet} 8s linear infinite;
    background-size: 350px;
  }
`

export const GifWrapper1 = styled(GifWrapper)`
  background-image: url(${gif1});
  animation: none;
`

export const GifWrapper2 = styled(GifWrapper)`
  background-image: url(${gif2});
`

export const GifWrapper3 = styled(GifWrapper)`
  background-image: url(${gif3});
`
