import styled from 'styled-components'

import { mq, sizes } from '../../theme'
import type { ThemeObject } from '../../templates/CaptureScreen'
import { Theme } from '../../types/global'

const backgroundSize = {
  wrapper: {
    mobile: {
      [Theme.bling]: '20%',
      [Theme.kitty]: '100%',
      [Theme.balenciaga]: '20%',
    },
    tablet: {
      [Theme.bling]: '20%',
      [Theme.kitty]: '50%',
      [Theme.balenciaga]: '20%',
    },
  },
  layer: {
    mobile: {
      [Theme.bling]: '100%',
      [Theme.kitty]: '100%',
      [Theme.balenciaga]: '65%',
    },
    tablet: {
      [Theme.bling]: '30%',
      [Theme.kitty]: '30%',
      [Theme.balenciaga]: '30%',
    },
  },
  gif: {
    mobile: {
      [Theme.bling]: '66%',
      [Theme.kitty]: '66%',
      [Theme.balenciaga]: '37%',
    },
    tablet: {
      [Theme.bling]: '30%',
      [Theme.kitty]: '30%',
      [Theme.balenciaga]: '30%',
    },
  },
}

export const BackgroundWrapper = styled.div<{ skin: ThemeObject }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-image: ${({ skin }) =>
    skin.background.includes('#') ? `unset` : `url(${skin.background})`};
  background-color: ${({ skin }) =>
    skin.background.includes('#') ? `${skin.background}` : `unset`};
  background-repeat: repeat;
  background-size: ${({ skin }) => backgroundSize.wrapper.mobile[skin.name]};
  @media ${mq(sizes.tablet)} {
    background-size: ${({ skin }) => backgroundSize.wrapper.tablet[skin.name]};
  }
`

export const LayerWrapper = styled.div<{ skin: ThemeObject }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-repeat: repeat;
  background-image: ${({ skin }) => `url(${skin.layer})`};
  background-size: ${({ skin }) => backgroundSize.layer.mobile[skin.name]};
  @media ${mq(sizes.tablet)} {
    background-size: ${({ skin }) => backgroundSize.layer.tablet[skin.name]};
  }
`

export const GifWrapper = styled.div<{ skin: ThemeObject }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-repeat: repeat;
  background-image: ${({ skin }) => `url(${skin.gif})`};
  background-size: ${({ skin }) => backgroundSize.gif.mobile[skin.name]};
  background-position: -50% 0;
  @media ${mq(sizes.tablet)} {
    background-size: ${({ skin }) => backgroundSize.gif.tablet[skin.name]};
  }
`
