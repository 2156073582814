import React, { FC, useCallback, useState } from 'react'

import withMemo from '../../decorators/withMemo'
import { Theme, Mode } from '../../types/global'
import { pixiCanvas } from '../../components/PhotoCanvas/'

import * as SC from './styled'

export type DebugTemplateProps = any

const DebugTemplate: FC<DebugTemplateProps> = () => {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>()
  const [currentTheme, setCurrentTheme] = useState<Theme>(Theme.balenciaga)
  const [currentMode, setCurrentMode] = useState<Mode>(Mode.solo)

  // handlers

  const handleOnVideoPlaying = useCallback((video: HTMLVideoElement) => {
    setVideoElement(video)
  }, [])

  return (
    <SC.DebugTemplate>
      <SC.Webcam onVideoCanPlay={handleOnVideoPlaying} />
      <SC.Canvas
        webcamVideoElement={videoElement}
        currentTheme={currentTheme}
        currentMode={currentMode}
      />
      <SC.Buttons>
        <button onClick={() => setCurrentTheme(Theme.balenciaga)}>Balenciaga</button>
        <button onClick={() => setCurrentTheme(Theme.bling)}>Bling</button>
        <button onClick={() => setCurrentTheme(Theme.kitty)}>Kitty</button>
        <button onClick={() => setCurrentMode(Mode.solo)}>SOLO</button>
        <button onClick={() => setCurrentMode(Mode.duo)}>DUO</button>
        <button onClick={() => pixiCanvas?.takeFinalPicture()}>Export</button>
      </SC.Buttons>
    </SC.DebugTemplate>
  )
}

export default withMemo(DebugTemplate)
