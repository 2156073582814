import React, { ComponentType } from 'react'

/**
 * Memoized the WrappedComponent
 */

// eslint-disable-next-line @typescript-eslint/ban-types
const withMemo = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithMemo = React.memo(WrappedComponent)

  WithMemo.displayName = `WithMemo(${WrappedComponent.displayName ?? WrappedComponent.name})`

  return WithMemo
}

export default withMemo
