import React, { FC } from 'react'

import withMemo from '../../decorators/withMemo'
import type { ThemeObject } from '../../templates/CaptureScreen'

import * as SC from './styled'

export type ThemedBackgroundProps = {
  skin: ThemeObject
}

const ThemedBackground: FC<ThemedBackgroundProps> = (props) => {
  const { skin } = props

  return (
    <>
      <SC.BackgroundWrapper skin={skin} />
      {skin.layer && <SC.LayerWrapper skin={skin} />}
      <SC.GifWrapper skin={skin} />
    </>
  )
}

export default withMemo(ThemedBackground)
