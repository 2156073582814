import React, { useCallback, FC } from 'react'

import { withMemo } from '../../decorators'

import { icons } from './Icon.assets'
import * as SC from './styled'

export type IconProps = {
  className?: string
  icon: string
  color?: string
  iconProps?: any
  onClick?: () => void
  link?: string
  route?: string
  routeParams?: { [key: string]: string }
  target?: string
  seoTitle?: string
}

const Icon: FC<IconProps> = ({
  className,
  icon,
  color,
  link,
  route,
  routeParams,
  target,
  onClick,
  seoTitle,
  iconProps,
}) => {
  const iconComponent = useCallback(() => (typeof icon === 'string' ? icons[icon] : icon), [icon])

  const renderAsLink = useCallback(
    (IconComponent) => (
      <SC.IconLinkWrapper
        className={className}
        onClick={onClick ?? undefined}
        role="presentation"
        color={color}
      >
        <SC.IconLink
          link={link}
          route={route}
          routeParams={routeParams}
          title={seoTitle}
          target={target}
          rel="noreferrer"
        >
          <IconComponent {...iconProps} />
        </SC.IconLink>
      </SC.IconLinkWrapper>
    ),
    [color, iconProps, link, onClick, route, routeParams, seoTitle, className, target]
  )

  const renderDefault = useCallback(
    (IconComponent) => (
      <SC.IconSpan
        className={className}
        onClick={onClick ?? undefined}
        color={color}
        role="presentation"
      >
        <IconComponent {...iconProps} />
      </SC.IconSpan>
    ),
    [color, iconProps, onClick, className]
  )

  if (typeof icon === 'undefined') {
    return null
  }

  if (typeof iconComponent === 'undefined') {
    return null
  }

  return link || route ? renderAsLink(iconComponent) : renderDefault(iconComponent)
}

export default withMemo(Icon)
