import React, { FC } from 'react'
import cx from 'classnames'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import isString from '../../utils/IsString'

// /!\ To test gatby-image integration in storybook :
// query all images in src/images and get one image with
// img props when is a string (ex: 'article.png')

export enum ImageLoading {
  lazy = 'lazy',
  eager = 'eager',
  auto = 'auto',
}

export type ImageProps = {
  className?: string
  img: any
  loading?: ImageLoading
  alt: string
}

const Image: FC<ImageProps> = (props) => {
  const { img, className, loading = ImageLoading.lazy, alt = '' } = props

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)

  if (isString(img)) {
    const image = img
      ? data.allImageSharp.edges.find((edge: any) => edge.node.fluid.originalName === img)
      : null

    return image ? (
      <Img
        fluid={image.node.fluid}
        className={cx(className)}
        loading={loading}
        alt={alt}
        fadeIn={false}
      />
    ) : null
  }
  return <Img fluid={img} className={cx(className)} loading={loading} alt={alt} fadeIn={false} />
}

export default Image
