import React from 'react'

export const iconsKeys = {
  SocialInstagram: 'social-instagram',
  LeftArrow: 'left-arrow',
  RightArrow: 'right-arrow',
  Camera: 'camera',
  Heart: 'heart',
  DoubleHeart: 'double-hearts',
}

type IconWrapperProps = (fileName: string, width: number, height: number) => JSX.Element

const IconWrapper: IconWrapperProps = (fileName, width, height) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <use xlinkHref={`/icones/spritemap.svg#${fileName}`} />
  </svg>
)

export const icons = {
  [iconsKeys.SocialInstagram]: IconWrapper('social-instagram', 12, 12),
  [iconsKeys.LeftArrow]: IconWrapper('left-arrow', 20, 32),
  [iconsKeys.RightArrow]: IconWrapper('right-arrow', 20, 32),
  [iconsKeys.Camera]: IconWrapper('camera', 38, 50),
  [iconsKeys.Heart]: IconWrapper('heart', 28, 32),
  [iconsKeys.DoubleHeart]: IconWrapper('double-hearts', 35, 35),
}

export default icons
