import React, { FC, useCallback, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import classnames from 'classnames'

import { iconsKeys } from '../../components/Icon/Icon.assets'
import ThemedBackground from '../../components/ThemedBackground'
import withMemo from '../../decorators/withMemo'
import { Mode, Theme } from '../../types/global'
import { pixiCanvas } from '../../components/PhotoCanvas'

import * as SC from './styled'

export type ThemeObject = {
  name: Theme
  background: string
  layer: string
  gif: string
}

const themes: ThemeObject[] = [
  {
    name: Theme.bling,
    background: '/static/images/blingbackground.png',
    layer: '/static/images/blinglayer.png',
    gif: '/static/images/blinggif.gif',
  },
  {
    name: Theme.kitty,
    background: '/static/images/kittybackground.png',
    layer: '',
    gif: '/static/images/kittygif.gif',
  },
  {
    name: Theme.balenciaga,
    background: '#28c8ff',
    layer: '/static/images/balenciagalayer.png',
    gif: '/static/images/balenciagagif.gif',
  },
]

export type CaptureTemplateProps = {
  onPictureTaken?: (image: string, theme: string, mode: string) => void
  onModeChange: (mode: Mode) => void
  onThemeIndexChange: (index: number) => void
  themeIndex: number
  mode: Mode
}

const CaptureTemplate: FC<CaptureTemplateProps> = ({
  mode,
  themeIndex,
  onPictureTaken,
  onModeChange,
  onThemeIndexChange,
}) => {
  // state

  const [isOnCapture, setIsOnCapture] = useState(false)
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>()
  const [isIntermediatePictureTaken, setIsIntermediatePictureTaken] = useState(false)
  const [countdown, setCountdown] = useState<number | null>(null)

  // functions

  const increaseTheme = useCallback(() => {
    if (themeIndex !== themes.length - 1) {
      onThemeIndexChange(themeIndex + 1)
    } else {
      onThemeIndexChange(0)
    }
  }, [onThemeIndexChange, themeIndex])

  const decreaseTheme = useCallback(() => {
    if (themeIndex !== 0) {
      onThemeIndexChange(themeIndex - 1)
    } else {
      onThemeIndexChange(themes.length - 1)
    }
  }, [onThemeIndexChange, themeIndex])

  const takeIntermediatePicture = useCallback(() => {
    setIsIntermediatePictureTaken(true)
    pixiCanvas?.takeIntermediatePicture()
  }, [])

  const takeFinalPicture = useCallback(() => {
    const base64Image = pixiCanvas?.takeFinalPicture()
    onPictureTaken?.(base64Image, themes[themeIndex].name, mode)
  }, [onPictureTaken, themeIndex, mode])

  const startCountdown = useCallback(() => {
    const flash = document.querySelector('.flash')

    const countdownAudio = new Audio('/static/audio/countdown.mp3')
    const captureAudio = new Audio('/static/audio/capture.mp3')

    setIsOnCapture(true)

    const tl = gsap.timeline()

    tl.add(() => {
      setCountdown(null)
    }, 0)
      .add(() => {
        setCountdown(3)
        countdownAudio.play()
      }, '+=1')
      .add(() => {
        setCountdown(2)
      }, '+=1')
      .add(() => {
        setCountdown(1)
      }, '+=1')
      .add(() => {
        setCountdown(0)
      }, '+=1')
      .to(
        flash,
        {
          duration: 0.15,
          opacity: 1,
        },
        '+=0.1'
      )
      .add(() => captureAudio.play(), '-=0.25')
      .add(() => {
        if (mode === Mode.solo || (mode === Mode.duo && isIntermediatePictureTaken)) {
          takeFinalPicture()
        } else {
          takeIntermediatePicture()
        }
        setIsOnCapture(false)
      }, '+=0')
      .to(
        flash,
        {
          duration: 0.3,
          opacity: 0,
        },
        '+=0.7'
      )
  }, [isIntermediatePictureTaken, mode, takeFinalPicture, takeIntermediatePicture])

  // handlers

  const handleCaptureClick = useCallback(() => {
    startCountdown()
  }, [startCountdown])

  const handleOnVideoCanPlay = useCallback((video: HTMLVideoElement) => {
    setVideoElement(video)
  }, [])

  // effects

  useEffect(() => {
    if (mode === Mode.duo && isIntermediatePictureTaken) {
      setTimeout(() => {
        startCountdown()
      }, 500)
    }
  }, [isIntermediatePictureTaken, mode, startCountdown])

  // return

  return (
    <SC.CaptureTemplate>
      {!isOnCapture && (
        <SC.TopMenu>
          <SC.Button
            className={`heart ${mode === Mode.solo ? 'active' : ''}`}
            onClick={() => onModeChange(Mode.solo)}
          >
            <SC.Heart icon={iconsKeys.Heart} />
          </SC.Button>
          <SC.Button
            className={`double ${mode === Mode.duo ? 'active' : ''}`}
            onClick={() => onModeChange(Mode.duo)}
          >
            <SC.DoubleHearts icon={iconsKeys.DoubleHeart} />
          </SC.Button>
        </SC.TopMenu>
      )}
      <ThemedBackground skin={themes[themeIndex]} />
      {!isOnCapture && (
        <SC.BottomMenu
          onIncreaseTheme={increaseTheme}
          onDecreaseTheme={decreaseTheme}
          onCapture={handleCaptureClick}
        />
      )}
      <SC.CountdownBanner className={isOnCapture ? 'active' : ''}>
        <SC.Countdown>
          <SC.Count className={classnames('three', { active: countdown === 3 })}>3</SC.Count>
          <SC.Count className={classnames('two', { active: countdown === 2 })}>2</SC.Count>
          <SC.Count className={classnames('one', { active: countdown === 1 })}>1</SC.Count>
          <SC.CameraIcon
            className={classnames('cam', { active: countdown === 0 })}
            icon={iconsKeys.Camera}
          />
        </SC.Countdown>
      </SC.CountdownBanner>
      <SC.Canvas
        webcamVideoElement={videoElement}
        currentTheme={themes[themeIndex].name}
        currentMode={mode}
      />
      <SC.Webcam onVideoCanPlay={handleOnVideoCanPlay} />
    </SC.CaptureTemplate>
  )
}

export default withMemo(CaptureTemplate)
