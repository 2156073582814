import styled from 'styled-components'

import { ActionButton } from '../../components'
import { colors, textStyles, mq, sizes } from '../../theme'

export const HomeTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.pureWhite};
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
`

export const Text = styled.p`
  ${textStyles.button}
`

export const PopupImg = styled.img`
  opacity: 0;
  z-index: 3;
  width: 95%;
  transform: translateY(40px);
  max-width: 400px;
  margin-bottom: 15px;
  @media ${mq(sizes.tablet)} {
    max-width: 500px;
    margin-bottom: 20px;
  }
`

export const StartButton = styled(ActionButton)`
  position: relative;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  ${textStyles.button}
  padding: 14px 23px;
  width: 200px;
  margin: 0 auto;
`

export const Disclaimer = styled.label<{ hasError: boolean }>`
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    margin-left: 10px;
    color: ${({ hasError }) => (hasError ? 'red' : 'white')};
    ${textStyles.disclaimer};
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
`

export const BottomGradient = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
`

export const Overlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  pointer-events: none;
  transform-origin: center bottom;
`
