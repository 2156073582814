import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

export type SeoProps = {
  title?: string
  description?: string
  image?: string
  twitter_card?: string
  url?: string
  type?: string
  twitter_account?: string
  lang?: string
  robots?: string
}

const Seo: FC<SeoProps> = (props) => {
  const {
    title = 'Make your own Balenciaga Valentine',
    description = '',
    image = null,
    twitter_card = 'summary_large_image',
    url = '',
    type = 'website',
    twitter_account = null,
    lang = null,
    robots = null,
  } = props

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      {/* <meta property="og:locale" content={isoCode} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content={twitter_card} />
      {robots && <meta property="robots" content={robots} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {twitter_account && <meta property="twitter:site" content={twitter_account} />}
      {twitter_account && <meta name="twitter:creator" content={twitter_account} />}
      {lang && <html lang={lang} />}
    </Helmet>
  )
}

export default Seo
