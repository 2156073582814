import React, { FC } from 'react'

import { iconsKeys } from '../Icon/Icon.assets'
import { withMemo } from '../../decorators'

import * as SC from './styled'

export type BottomMenuProps = {
  className?: string
  onIncreaseTheme: () => void
  onDecreaseTheme: () => void
  onCapture: () => void
}

const BottomMenu: FC<BottomMenuProps> = (props) => {
  const { className, onIncreaseTheme, onDecreaseTheme, onCapture } = props

  return (
    <SC.BottomMenu className={className}>
      <SC.Button onClick={onDecreaseTheme}>
        <SC.LeftArrow icon={iconsKeys.LeftArrow} />
      </SC.Button>
      <SC.Button className="camera" onClick={onCapture}>
        <SC.Camera icon={iconsKeys.Camera} />
      </SC.Button>
      <SC.Button onClick={onIncreaseTheme}>
        <SC.RightArrow icon={iconsKeys.RightArrow} />
      </SC.Button>
    </SC.BottomMenu>
  )
}

export default withMemo(BottomMenu)
