import React from 'react'

import { withMemo } from '../../decorators'

import * as SC from './styled'

const MainBackground = () => {
  return (
    <>
      <SC.BackgroundWrapper />
      <SC.GifWrapper1 />
      <SC.GifWrapper2 />
      <SC.GifWrapper3 />
    </>
  )
}

export default withMemo(MainBackground)
