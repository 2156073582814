import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const styles = css``

export const LinkA = styled.a`
  ${styles}
`

export const LinkGatsby = styled(Link)`
  ${styles}
`
